import React from 'react';
import logo from './Under_Construction_bear.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Whoops, we're still in construction. Sorry!
        </p>
      </header>
    </div>
  );
}


export default App;
